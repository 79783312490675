import { Component, OnInit } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import Utils from "@shared/helpers/utils";
import {
  ICampus,
  IPeriodo,
  IProgramaAcademico,
} from "@shared/Interface/ICatalogos.interface";
import {
  IRetoMaestro,
} from "@shared/Interface/IReto.interface";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { IUser } from "@shared/Interface/user";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";
import Swal from "sweetalert2";

interface Retos {
  campus: string;
  descripcion: string;
  idReto: number;
  matchScore: number;
  periodo: string;
  programaAcademico: string;
  reto: string;
  semestre: number;
  unidadFormativa: string;
}
@Component({
  selector: 'app-retos',
  templateUrl: './retos.component.html',
  styleUrls: ['./retos.component.css'],
})
export class RetosComponent implements OnInit {
  ACADEMICOBUSCANDOSFSINSOCIO = 3;

  userStore!: IUser;
  lang: string = 'es';

  clavePerfil!: string;
  socioFormador!: ISocioFormadorResponse;

  idReto: number = 0;

  retoDetalle: IRetoMaestro;

  // Secciones de cuadro
  lbl_alcance = 'Multi-Alcance';
  lbl_semestre = 'Multi-Semestre';
  lbl_semana_numero = '1';
  lbl_semana = '1 Semana';
  lbl_idioma_numero = 'Es';
  lbl_idioma = 'Multi-Idioma';

  // seccion detalle
  lbl_det_alcance = '';
  lbl_det_semestre = '';
  lbl_det_idioma = '';

  lbl_det_programaAcademico = '';

  lbl_sf_sectores = 'Sector:<br /> ';
  lbl_sf_tamanos = 'Tamaño:<br /> ';
  lbl_sf_industria = 'Industria:<br /> ';
  lbl_sf_presencias = 'Presencia:<br /> ';
  lbl_sf_descripcion = '';
  lbl_periodosSemestrales = '';
  lbl_periodosSemanales = '';

  retoSelected: Retos;
  contextoOrigen: string;

  pedirPeriodoAcademico = false;
  periodoAcademicoSeleccionado: number;
  periodoAcademicoData: IPeriodo[];
  
  
  CampusSeleccionado: number;
  pedirListaCampus = false;
  pedirCampusCoordinador = false;
  campusData: ICampus[];
  seccion1: ICampus[];
  seccion2: ICampus[];
  selectedCampus: ICampus[] = [];

  imgReto: string = '/assets/img/img_reto_fullsize.jpg';
  imgQuieroVincularlo: string = 'assets/img/icon_abajo.svg';

  mostrarAltaArchivo: boolean = false;

  urlTree: UrlTree;
  modoConsulta: boolean = true;
  mostrarFormulario: boolean = false;
  idLogBusquedaReto: number = 0;
  idEscuelaAcademico: number = 0;
  idEscuelaRetoMaestro: number = 0;

  constructor(
    private retosService: RetosService,
    private router: Router,
    private usuarioService: UsuariosService,
    private solicitudesService: SolicitudesService,
    private socioFormadorService: SociosFormadoresService,
    private catalogosService: CatalogosService,
    private azureBlobStorage: AzureBlobStorageService,
    private _toaster: ToastrService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang') ?? 'es';

    this.mostrarFormulario = false;

    this.contextoOrigen = localStorage.getItem('contextoOrigen') ?? '';
    //localStorage.removeItem('contextoOrigen');

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.userStore = this.usuarioService.getCurrentUserData();
    this.idEscuelaAcademico = this.userStore.idEscuela;

    this.urlTree = this.router.parseUrl(this.router.url);
    this.idReto = Number(this.urlTree.queryParams['idRetoMaestro'] ?? 0);
    if (this.idReto == 0) {
      this.idReto = Number(sessionStorage.getItem('idRetoMaestro'));
    }
    this.idLogBusquedaReto = Number(
      sessionStorage.getItem('idLogBusquedaReto')
    );

    if (this.contextoOrigen == 'bancoRetos') {
      this.modoConsulta = false;
    }

    if (this.contextoOrigen == 'matchReto') {
      this.modoConsulta = false;
      let rs = localStorage.getItem('retoSelected') ?? '{}';
      this.retoSelected = JSON.parse(rs);
    }

    this.getReto().catch((e) => Utils.processErrorResponse(e));

    this.cargaCatalogos();

    // Obtenemos el Socio Formador
    this.getSocioFormadorActual();

    this.checkUploadImage();
  }

  cargaCatalogos() {
    // Cargar Periodos   AGO-DIC 2023
    this.catalogosService
      .getPeriodo()
      .pipe(
        tap((resultado: IPeriodo[]) => (this.periodoAcademicoData = resultado))
      )
      .subscribe();

    // Cargar campus donde llevar el reto
    this.catalogosService
      .getCampusxReto(this.idReto)
      .pipe(
        tap((resultado: ICampus[]) => {
          // 20240718: solicitado por lulú: Zacatecas, Obregón, Irapuato, Chiapas, CD Juarez,  Nacional
          let campusExcluidos = [52, 31, 36, 27, 30, 53];
          // Se quitan los campos solicitados
          let campusFiltrados = resultado.filter(
            (campus) => !campusExcluidos.includes(campus.idCampus)
          );
          this.campusData = campusFiltrados;

          // Se realiza ordenamiento alfabetico en dos columnas solicitado
          if (this.campusData) {
            let halfItems = Math.round(this.campusData.length / 2);
            this.seccion1 = this.campusData.slice(0, halfItems);
            this.seccion2 = this.campusData.slice(halfItems);
          }
        })
      )
      .subscribe();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  getSocioFormadorActual() {
    if (this.userStore.idSocioFormador != 0) {
      this.socioFormadorService
        .getSociosFormadoresById(this.userStore.idSocioFormador)
        .subscribe((data) => {
          this.socioFormador = data;
        });
    }
  }

  async getReto() {
    try {
      this.retosService.getRetoMaestroDetail(this.idReto).subscribe({
        next: (resOK) => {
          this.retoDetalle = resOK;
          this.idEscuelaRetoMaestro = this.retoDetalle.idEscuela;
          this.imgReto = this.retoDetalle.urlFotoReto ?? this.imgReto;

          this.lbl_sf_sectores += this.retoDetalle.sectores
            ?.map((sector) => sector.sector)
            .join(', ');
          this.lbl_sf_tamanos += this.retoDetalle.tamanos
            ?.map((tamano) => tamano.tamano)
            .join(', ');
          this.lbl_sf_industria += this.retoDetalle.industrias
            ?.map((industria) => industria.industria)
            .join(', ');
          this.lbl_sf_presencias += this.retoDetalle.presencias
            ?.map((presencia) => presencia.presencia)
            .join(', ');
          this.lbl_sf_descripcion +=
            this.retoDetalle.palabrasClave ||
            'Sin descripción de Socio Formador';

          this.lbl_det_programaAcademico = this.retoDetalle.programasAcademicos
            .map((x: IProgramaAcademico) => x.programaAcademico)
            .join(', ');

          this.lbl_det_alcance = this.retoDetalle.alcances
            ?.map((alcance) => alcance.alcance)
            .join(',');

          if (!this.lbl_det_alcance.includes(',')) {
            this.lbl_alcance = this.lbl_det_alcance;
          }

          this.lbl_alcance =
            this.lbl_alcance.length == 0 ? '-' : this.lbl_alcance;

          this.lbl_det_semestre = this.retoDetalle.semestres
            ?.map((semestre) => semestre.semestre)
            .join(',');

          if (!this.lbl_det_semestre.includes(',')) {
            this.lbl_semestre = `${this.lbl_det_semestre} Semestre`;
          }

          this.lbl_semestre =
            this.lbl_semestre.length == 0 ? '-' : this.lbl_semestre;

          this.lbl_semana = this.retoDetalle.semanas || '';
          if (this.lbl_semana.length > 0) {
            this.lbl_semana_numero = this.lbl_semana.replace(' semanas', '');
          }

          this.lbl_semana_numero =
            this.lbl_semana_numero.length == 0 ? '-' : this.lbl_semana_numero;

          this.lbl_det_idioma = this.retoDetalle.idiomas
            ?.map((idioma) => idioma.idioma)
            .join(',');

          if (this.retoDetalle.idiomas.length > 1) {
            this.lbl_idioma_numero = '*';
          } else {
            this.lbl_idioma_numero = this.lbl_det_idioma.slice(0, 2);
          }

          if (!this.lbl_det_idioma.includes(',')) {
            this.lbl_idioma = this.lbl_det_idioma;
          }

          this.lbl_idioma =
            this.lbl_idioma.length === 0 ? '-' : this.lbl_idioma;

          this.lbl_periodosSemestrales = this.retoDetalle.periodosSemestrales
            .map((x) => x.periodoSemestral)
            .join(', ');

          this.lbl_periodosSemanales = this.retoDetalle.periodosSemanales
            .map((x) => x.periodoSemanal)
            .join(', ');

          this.retoDetalle.horasServicioSocial =
            this.retoDetalle.horasServicioSocial ?? '0';
        },
        error: (err) => {
          console.log(err);
        },
      });
    } catch (error) {
      console.error('Error al obtener el detalle de un reto por id ', error);
    }
  }

  validaFormulario() {
    let regresa = true;
    this.pedirListaCampus = false;
    this.pedirCampusCoordinador = false;
    this.pedirPeriodoAcademico = false;

    // validamos campos

    if (this.selectedCampus.length == 0) {
      this.pedirListaCampus = true;
      regresa = false;
    }

    if ((this.CampusSeleccionado ?? 0) == 0) {
      this.pedirCampusCoordinador = true;
      regresa = false;
    }

    if ((this.periodoAcademicoSeleccionado ?? 0) == 0) {
      this.pedirPeriodoAcademico = true;
      regresa = false;
    }

    return regresa;
  }

  async quieroVincularme() {
    let idProblematica = localStorage.getItem('problematica') ?? 0;

    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    if (this.validaFormulario()) {
      this.solicitudesService
        .postSolicitud({
          idTipoSolicitud: 2,
          descripcionSolicitud: `Solicitud del Socio Formador ${this.socioFormador.nombreSocioFormador} para vinculación a Reto ${this.retoDetalle.reto}`,
          idSocioFormador: this.userStore.idSocioFormador,
          idCVDP: 0,
          idAcademia: 0,
          idProblematica: idProblematica,
          idRetoMaestro: this.retoDetalle.idRetoMaestro,
          idCampus: this.CampusSeleccionado,
          campusSeleccionados: this.selectedCampus.map((x) => x.idCampus),
          idPeriodo: this.periodoAcademicoSeleccionado,
          idUsuario: idUsuario,
        })
        .subscribe({
          next: (data) => {
            if (this.contextoOrigen == 'matchReto') {
              //Aqui registramos el log de las busquedas, si viene del matchReto
              let tmppc = localStorage.getItem('ppc');
              let retoSelected = JSON.parse(
                localStorage.getItem('retoSelected') ?? '{}'
              );

              let userStore = this.usuarioService.getCurrentUserData();

              let ppc = JSON.parse(tmppc ?? '');
              let pmatch = retoSelected.matchScore / 100;

              this.retosService
                .postVinculacionProblematica({
                  idLogBusquedaReto: this.idLogBusquedaReto,
                  idRetoMaestro: retoSelected.idReto,
                  matchScore: retoSelected.matchScore,
                })
                .subscribe();

              let idLogBusquedaReto =
                sessionStorage.getItem('idLogBusquedaReto') ?? 0;

              this.solicitudesService
                .putSolicitud({
                  idSolicitud: data.idSolicitud,
                  idLogBusquedaReto: Number(idLogBusquedaReto),
                })
                .subscribe();

              this.retosService
                .postMatch({
                  idUsuario: userStore.idUsuario,
                  parametros: ppc,
                  porcentajeMatch: pmatch,
                  idSocioFormador: 0,
                  idReto: retoSelected.idReto,
                })
                .subscribe({
                  next: (resOK) => {
                    localStorage.removeItem('contextoOrigen');
                    localStorage.removeItem('ppc');

                    this.router.navigate(['reto-confirmacion']);
                  },
                });
            } else {
              localStorage.setItem(
                'retoSelected',
                JSON.stringify(this.retoDetalle)
              );
              this.router.navigate(['reto-confirmacion']);
            }
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: error,
            });
          },
        });
    }
  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = event.target.value;
  }

  async selImagen(event: any) {
    let id_reto = this.retoDetalle.idRetoMaestro;
    let id_retoMaestro = this.retoDetalle.idRetoMaestro;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    if (!selFile.type.includes('image/')) {
      this._toaster.error('Únicamente imágenes');
      return;
    }
    if (selFile.size > 3e6) {
      this._toaster.error('Tamaño máximo de 3MB');
      return;
    }

    let fileName = this.getFileName(id_reto, selFile);

    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameUsuario,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_usuarSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_usuarURL}${fileName}`;

        this.retosService
          .putFotos({
            idReto: 0,
            idRetoMaestro: id_retoMaestro,
            urlFoto: fileUrlLogo,
          })
          .subscribe((resOK) => {
            this.imgReto = fileUrlLogo;
          });
      });
  }

  getFileName(id, file) {
    let prex = environment.name != 'production' ? 'test_' : '';
    return `${prex}reto_${id}.${file.name.split('.').pop()}`;
  }

  checkUploadImage() {
    if (
      this.usuarioService
        .getCurrentUserData()
        .claveRoles.includes('disenador') ||
      ['cvdp_admin'].includes(
        this.usuarioService.getCurrentUserData().clavePerfil
      )
    ) {
      this.mostrarAltaArchivo = true;
    }
  }

  mostarFormularioVinculacion() {
    this.mostrarFormulario = !this.mostrarFormulario;
    this.imgQuieroVincularlo = this.mostrarFormulario
      ? 'assets/img/icon_arriba.svg'
      : 'assets/img/icon_abajo.svg';
  }

  async academicoQuieroVincularme() {
    let userStore = this.usuarioService.getCurrentUserData();
    let idUsuario = userStore.idUsuario;
    if (this.validaFormularioAcademico()) {
      this.solicitudesService
        .postSolicitud({
          idTipoSolicitud: 3,
          descripcionSolicitud:
            'Solicitud sin Socio Formador desde Banco de Retos',
          idSocioFormador: 0,
          idCVDP: 0,
          idAcademia: idUsuario,
          idUsuario: idUsuario,
          idProblematica: 0,
          idRetoMaestro: this.idReto,
          idCampus: userStore.idCampus,
          campusSeleccionados: null,
          idPeriodo: this.periodoAcademicoSeleccionado,
        })
        .subscribe({
          next: (data) => {
            let idLogBusquedaReto =
              sessionStorage.getItem('idLogBusquedaReto') ?? 0;
            this.solicitudesService
              .putSolicitud({
                idSolicitud: data.idSolicitud,
                idLogBusquedaReto: Number(idLogBusquedaReto),
              })
              .subscribe();
            localStorage.setItem(
              'retoSelected',
              JSON.stringify(this.retoDetalle)
            );
            localStorage.removeItem('contextoOrigen');
            localStorage.removeItem('ppc');
            this.router.navigate(['reto-confirmacion']);
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: error,
            });
          },
        });
    }
  }

  seleccionar(event: any) {
    this.CampusSeleccionado = event.target.value;
  }

  onCheckboxChange(event: any, typeData: string) {
    if (event.target.checked) {
      const obj = this.campusData.find((x) => {
        return x.idCampus === +event.target.value;
      });
      this.selectedCampus.push(obj!);
    } else {
      const index = this.selectedCampus.findIndex(
        (x) => x.idCampus === +event.target.value
      );
      this.selectedCampus.splice(index, 1);
    }
    document.getElementById('campusReto')?.focus();
  }

  validaFormularioAcademico() {
    let regresa = true;
    this.pedirListaCampus = false;
    this.pedirCampusCoordinador = false;
    this.pedirPeriodoAcademico = false;

    // validamos campos
    if ((this.periodoAcademicoSeleccionado ?? 0) == 0) {
      this.pedirPeriodoAcademico = true;
      regresa = false;
    }

    return regresa;
  }
}
