<div class="row">
  <!-- btn quiero vincularme -->
  <div
    class="col-12 mt-4"
    *ngIf="['academia'].includes(userStore.clavePerfil) && showToggleButton"
  >
    <button (click)="mostarFormularioVinculacion()" class="btn-amarillo">
      {{ buttonText }}&nbsp;<img
        id="imgQuieroVincularlo"
        alt="Quiero vincularlo"
        width="20px"
        src="{{ imgQuieroVincularlo }}"
      />
    </button>
  </div>

  <div
    class="row"
    *ngIf="MostrarSolicitudVinculacionAcademico() && mostrarDivSolVinculacion"
    id="SolicitudVinculacion"
  >
    <hr style="color: #bedcfe" *ngIf="!showOnlyForm" />

    <!-- Col 1 Sección de formulario de la solicitud -->
    <div
      [ngClass]="{
        'col-xs-12 col-lg-6': showDescriptionReto,
        'col-12': !showDescriptionReto
      }"
    >
      <div
        class="mt-2 color-text-red-note"
        *ngIf="idTipoSolicitud == ACADEMICOBUSCANDOSFSINSOCIO"
      >
        {{ textToAcademicUser }}
      </div>

      <span class="subtitlecard mb-2" *ngIf="!showOnlyForm"
        >Quiero vincular este Socio Formador a un Reto:</span
      >

      <!-- solicitar características -->
      <div class="row" *ngIf="askForPartnerDetails">
        <div class="col-12">
          <label for="centregable"
            >{{ "no_encontre_sf.p1" | translate
            }}<span class="requerido">*</span></label
          >
          <div class="requerido" *ngIf="pedirCaracteristicas">
            Este campo es requerido
          </div>
          <textarea
            class="forma_area"
            id="centregable"
            [formControl]="caracteristicas_sf"
            placeholder="{{ 'no_encontre_sf.p1_place' | translate }}"
            required
          ></textarea>
        </div>
      </div>

      <!-- Si lo requiere escuela y Reto Maestro-->
      <div class="row">
        <form [formGroup]="vinculacionForm" class="mt-2" autocomplete="off">
          <!-- Escuela -->
          <div class="col-12" *ngIf="!tieneReto">
            <span class="question-text"
              >Selecciona la Escuela <span class="requerido">*</span></span
            >

            <div
              class="requerido"
              *ngIf="mostrarRequeridos && vinculacionForm.get('idEscuela')?.errors?.['required']"
            >
              Este campo es requerido
            </div>

            <ng-select
              aria-label="Escuela"
              placeholder="Selecciona una opción"
              formControlName="idEscuela"
              (change)="this.getUnidadFormativa($event)"
            >
              <ng-option
                *ngFor="let escuela of escuelaData"
                [value]="escuela.idEscuela"
              >
                {{ escuela.escuela }}
              </ng-option>
            </ng-select>
            <br />
          </div>

          <!-- Unidad Formativa -->
          <div class="col-12" *ngIf="!tieneReto">
            <span class="question-text"
              >Selecciona la Unidad Formativa
              <span class="requerido">*</span></span
            >

            <div
              class="requerido"
              *ngIf="mostrarRequeridos && vinculacionForm.get('idUnidadFormativa')?.errors?.['required']"
            >
              Este campo es requerido
            </div>

            <ng-select
              aria-label="Default example"
              formControlName="idUnidadFormativa"
              placeholder="Selecciona una opción"
              (change)="getRetoDetail($event)"
            >
              <ng-option
                *ngFor="let unidad of unidadFormativaData"
                [value]="unidad.idUnidadFormativa"
              >
                {{ unidad.clave }}_{{ unidad.unidadFormativa }}
              </ng-option>
            </ng-select>
            <br />
          </div>
        </form>
      </div>

      <!-- Académico solicitar a nombre de otro -->
      <div class="row">
        <div class="col-12 my-2">
          <span class="question-text my-2 ps-2">
            <input
              class="form-check-input-check me-1 align-middle"
              id="Solicitar_en_nombre_de_otro"
              (change)="changeAcademicoEnNombreDeOtro($event)"
              type="checkbox"
            />
            <label class="align-middle d-inline" for="Solicitar_en_nombre_de_otro">
              La solicitud se realizará en nombre de alguien más. Para que sea visible, esa persona debe pertenecer a tu mismo campus y escuela.</label
            >
          </span>

          <div
            class="ps-2"
            *ngIf="mostrarSeccionOtroProfesor"
          >
            <ng-select
              (ngModel)="(otroAcademicoSeleccionado)"
              (change)="seleccionarOtroAcademico($event)"
              [items]="academicosData"
              [bindLabel]="'nombreUsuario'"
              [bindValue]="'idUsuario'"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <!-- Periodo-->
      <div class="row">
        <div class="col-12 my-2">
          <span class="question-text my-2">{{
            "pagina_retos_detalles.selecciona_periodo_semestral_para_sf"
              | translate
          }}</span>
          <span class="requerido">&nbsp;*</span><br />
          <div class="requerido" *ngIf="pedirPeriodo">
            Este campo es requerido
          </div>
          <div class="ps-2">
            <ng-select
              id="periodoSemestral"
              name="periodoSemestral"
              placeholder="Selecciona una opción"
              (change)="seleccionarPeriodo($event)"
              bindLabel="periodo"
              bindValue="idPeriodo"
              [items]="periodoAcademicoData"
            ></ng-select>
          </div>
        </div>
      </div>

      <!-- Periodo semanal -->
      <div class="row">
        <div class="question-text mt-2">
          {{
            "pagina_retos_detalles.selecciona_periodo_semanal_para_sf"
              | translate
          }}
          <span class="requerido">&nbsp;*</span>
        </div>

        <div class="requerido" *ngIf="pedirPeriodoSemanal">
          Este campo es requerido
        </div>

        <div class="row my-2">
          <div
            class="col-sm-12 col-md-6"
            *ngFor="let periodoSemana of periodoSemanalData"
          >
            <span class="col-12 ps-2">
              <input
                class="form-check-input-check me-1 align-middle"
                id="periodoSemena_{{ periodoSemana.idPeriodoSemanal }}"
                (change)="verificaCheckPeriodoSemanal($event)"
                [checked]="verificaChecked(periodoSemana.idPeriodoSemanal)"
                value="{{ periodoSemana.idPeriodoSemanal }}"
                type="checkbox"
              />
              <label
                class="align-middle d-inline"
                for="periodoSemena_{{ periodoSemana.idPeriodoSemanal }}"
                >{{ periodoSemana.periodoSemanal }}</label
              >
            </span>
          </div>
        </div>
      </div>

      <!--Modalidad-->
      <div class="row">
        <div class="col-12 my-2">
          <span class="question-text">{{
            "pagina_retos_detalles.selecciona_modalidad" | translate
          }}</span
          ><span class="requerido">&nbsp;*</span><br />
          <div class="requerido" *ngIf="pedirModalidad">
            Este campo es requerido
          </div>
          <div class="ps-2">
            <ng-select
              id="modalidad"
              name="modalidad"
              [items]="listModalidad"
              (change)="seleccionarModalidad($event)"
              bindLabel="modalidad"
              bindValue="idModalidad"
              placeholder="Selecciona una opción"
            ></ng-select>
          </div>
        </div>
      </div>

      <!-- Alcance de la vinculación -->
      <div class="row">
        <div class="col-12 my-2">
          <span class="question-text">Alcance del Socio Formador</span
          ><span class="requerido">&nbsp;*</span><br />
          <div class="requerido" *ngIf="pedirAlcance">
            Este campo es requerido
          </div>
          <div class="ps-2">
            <ng-select
              [items]="listAlcance"
              bindLabel="alcance"
              bindValue="idAlcance"
              id="alcance"
              name="alcance"
              placeholder="Selecciona una opción"
              (change)="seleccionarAlcance($event)"
            ></ng-select>
          </div>
        </div>
      </div>

      <!-- Region donde se lleva el reto -->
      <div class="row" *ngIf="alcanceSeleccionado == ALCANCEREGION">
        <!-- Región a la que da servicio -->
        <div class="col-12 my-2">
          <label for="idRegion">Región para la que requieres el Socio Formador <span class="requerido">*</span></label>
          <div *ngIf="pedirRegion" class="requerido">
              Este campo es requerido
            </div>
          <div class="ps-2">
            <ng-select
              [items]="regionData"
              name="idRegion"
              bindLabel="region"
              bindValue="idRegion"
              [formControl]="idRegion"
              (change)="showCampus($event)"
              [placeholder]="'Selecciona una opción'"
            >
            </ng-select>
          </div>
        </div>

        <!-- Campus que da servicio -->

        <div class="col-12 my-2">
          <label for="idCampus">Campus en los que participará el Socio Formador<span class="requerido">*</span></label>
          <div class="ps-2">
            <div *ngIf="pedirCampusServicio" class="requerido">
              Este campo es requerido
            </div>
            <ng-select
              [items]="campusDataRegion"
              name="idCampus"
              [multiple]="true"
              bindLabel="campus"
              bindValue="idCampus"
              [formControl]="listaCampusAdicional"
              [placeholder]="'Selecciona una opción'"
              #ddCampusDataRegion
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="alcanceSeleccionado == ALCANCECAMPUS">
        <div class="col-12 my-2">
          <div class="row">
            <div class="">
              <span class="question-text">
                Campus en los que participará el Socio Formador
                <span class="requerido">*</span>
              </span>
            </div>

            <div class="requerido" *ngIf="pedirListaCampus">
              Este campo es requerido
            </div>

            <div class="col-12 my-2">
              <span class="text-muted ps-2">Selecciona una o más opciones</span>
              <div class="row">
                <div class="col-md-12 answer-text">
                  <div class="row">
                    <div class="col-6">
                      <div
                        class="col col-md-12 ps-2"
                        *ngFor="let campus of seccion1"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input-check"
                          id="{{ campus.idCampus }}-{{ campus.campus }}"
                          autocomplete="off"
                          value="{{ campus.idCampus }}"
                          (change)="onCheckboxChange($event, 'campus')"
                        />
                        <span class="ms-2">{{ campus.campus }}</span>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="col col-md-12 ps-2"
                        *ngFor="let campus of seccion2"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input-check"
                          id="{{ campus.idCampus }}-{{ campus.campus }}"
                          autocomplete="off"
                          value="{{ campus.idCampus }}"
                          (change)="onCheckboxChange($event, 'campus')"
                        />
                        <span class="ms-2">{{ campus.campus }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 my-3">
          <button
            type="button"
            (click)="quieroVincularmeaReto()"
            class="btn-amarillo"
          >
            Solicitar Vinculación
          </button>
        </div>
      </div>
    </div>

    <!-- Col 2 Seccion de información del Reto -->
    <div class="col-xs-12 col-lg-6" *ngIf="showDescriptionReto">
      <div class="row whiteBox">
        <div class="txtcard" *ngIf="loadingRetoMaestro">
          <div class="mt-4">Loading ...</div>
        </div>

        <!-- Información del reto seleccionado cuando viene del Banco -->
        <div *ngIf="retoInfo && showDescriptionReto && !loadingRetoMaestro">
          <div class="row">
            <div class="col-12">
              <span class="subtitlecard">Reto a vincular:</span><br />
              <span class="txtcard">{{ retoInfo.reto }}</span>
            </div>
          </div>

          <div class="col bkgatomic" *ngIf="!retoInfo">
            <span class="titatomic"
              >Selecciona un reto para ver su información
            </span>
          </div>
          <div class="col bkgatomic" *ngIf="retoInfo">
            <div class="row mb-4">
              <div class="col">
                <img
                  class="imgpchip_med"
                  alt="icon"
                  src="assets/svg/icon_info.svg"
                />&nbsp;
                <span class="titatomic">
                  <strong>Información del reto asociado a la UF</strong>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <span class="titsans">{{ retoInfo.reto }}</span>
              </div>
              <div class="col-12">
                <strong>Descripción:</strong>
              </div>
              <div class="col-12 mb-2">
                <span>{{ retoInfo.descripcion }}</span>
              </div>
              <div class="col-12">
                <strong>Objetivo del reto:</strong>
              </div>
            </div>
            <div class="col-12 mb-2">
              <span>{{ retoInfo.objetivo }}</span>
            </div>

            <div class="col-12">
              <strong>Entregable:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ retoInfo.entregable }}</span>
            </div>

            <div class="col-12">
              <strong>Idioma:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_idiomas }}</span>
            </div>

            <div class="col-12">
              <strong>Semestre:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_semestre }}</span>
            </div>

            <div class="col-12">
              <strong>Duración:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ retoInfo.semanas }}</span>
            </div>

            <div class="col-12">
              <strong>Periodo Semanal:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_periodoSemanal }}</span>
            </div>

            <div class="col-12">
              <strong>Periodo Semestral:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_periodoSemestral }}</span>
            </div>

            <div class="row">
              <div
                class="col-3 container-icons mb-3"
                *ngFor="let infoIcon of retoInfo.generalInfoIcons"
              >
                <app-icon-border
                  [icon]="infoIcon.icon"
                  [title]="infoIcon.title"
                ></app-icon-border>
              </div>
            </div>
            <div class="row" *ngIf="false">
              <div class="col d-flex justify-content-center">
                <app-button extraClass=""
                  >Crear nuevo reto a partir de existente</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
