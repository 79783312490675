import { Component, Input, OnInit } from '@angular/core';
import Utils from '@shared/helpers/utils';
import { IRetoExitosoResponse } from '@shared/Interface/ICasosExito';
import { IPeriodo } from '@shared/Interface/ICatalogos.interface';
import { CasosDeExitoService } from '@shared/service/Casos_de_Exito/casos-de-exito.service';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { RetosService } from '@shared/service/Retos/retos.service';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-socios-exitosos',
  templateUrl: './socios-exitosos.component.html',
  styleUrls: ['./socios-exitosos.component.css'],
})
export class SociosExitososComponent implements OnInit {
  @Input()
  IdReto: number;

  hayCasosDeExito: boolean = false;

  casosExito: IRetoExitosoResponse[];
  periodoData: IPeriodo[] = [];

  constructor(
    private casosDeExitoService: CasosDeExitoService,
    private catalogosService: CatalogosService,
    private retoServices: RetosService
  ) {}

  ngOnInit(): void {
    this.cargarCasosDeExito();
    this.cargarPeriodos();
  }

  async cargarPeriodos() {
    try {
      this.periodoData = await lastValueFrom(
        this.catalogosService.getPeriodo()
      );
    } catch (err) {
      Utils.processErrorResponse(err);
    }
  }

  async cargarCasosDeExito() {
    this.hayCasosDeExito = false;
    this.casosExito = [];
    if (this.IdReto) {
      try {
        this.casosExito = await lastValueFrom(
          this.casosDeExitoService.getnRetosExitososxReto(this.IdReto)
        );
      } catch (err) {
        Utils.processErrorResponse(err);
      }

      this.casosExito = this.casosExito.slice(0, 5);

      if (this.casosExito.length > 0) {
        this.hayCasosDeExito = true;
      }
    }
  }

  async mostrarDetalle(idReto) {
    try {
      let infoReto = await lastValueFrom(this.retoServices.getRetos(idReto));
      let infoPeriodo = '-';
      if (infoReto.idPeriodo > 0) {
        infoPeriodo =
          this.periodoData.find((x) => x.idPeriodo == infoReto.idPeriodo)
            ?.periodo ?? '-';
      }

      let caso = this.casosExito.find((x) => x.idReto == idReto);

      // obtenemos información del profesor
      let profesor = 'No Asignado';
      try {
        let profesores = await lastValueFrom(
          this.retoServices.getAllRetoProfesor(idReto)
        );
        let prof = profesores.find((x) => x.esCoordinador);
        profesor = prof.nombreUsuario + ' ' + prof.apellidoUsuario;
      } catch (error) {
        Utils.processErrorResponse(error);
      }

      // obteemos información del caso de éxito individual
      let casoDeExitoSeleccionado;
      if (caso) {
        try {
          casoDeExitoSeleccionado = await lastValueFrom(
            this.casosDeExitoService.getCasosById(caso.idCasoExito)
          );
        } catch (error) {
          Utils.processErrorResponse(error);
        }
      }

      let imagenMostrar;
      let lenImageurl = caso?.urlImagenResultado.length ?? 0;
      if (lenImageurl > 0) {
        imagenMostrar = caso?.urlImagenResultado;
      }
      if (imagenMostrar.length == 0) {
        imagenMostrar = casoDeExitoSeleccionado?.urlImagenCasoExito;
      }

      let mensaje =
        '<div class="row text-start w-100">' +
        '<div class="col-6">' +
        ' <div class="col-12 "><h5>Reto:</h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        caso?.nombreReto +
        '</h6></div>' +
        ' <div class="col-12 "><h5>Socio Formador:</h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        caso?.nombreSocioFormador +
        '</h6></div>' +
        ' <div class="col-12"><h5>Periodo: </h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        infoPeriodo +
        '</h6></div>' +
        ' <div class="col-12"><h5>Profesor: </h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        profesor +
        '</h6></div>' +
        ' <div class="col-12 d-none"><h5>Profesor(a) Coordinador(a): </h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        '' +
        '</h6></div>' +
        ' </div>' +
        '<div class="col-6">' +
        ' <img alt="' +
        caso?.nombreSocioFormador +
        '" title="' +
        caso?.nombreSocioFormador +
        '"' +
        '    style="width: 300px;height: 200px;object-fit: cover;"' +
        '    class="imgCasoExito" src="' +
        imagenMostrar +
        '">' +
        '</div>' +
        ' <div class="col-12"><h5>Descripción:</h5></div>' +
        ' <div class="col-12"><h6>&nbsp;&nbsp;' +
        caso?.descripcionReto +
        '</h6></div>';
      if (caso?.urlInformacionResultado) {
        mensaje +=
          ' <div class="col-12"><h5>Liga de interés:</h5></div>' +
          ' <div class="col-12"><a target="_blank" href="' +
          caso?.urlInformacionResultado +
          '">' +
          caso?.urlInformacionResultado +
          '</a></div>';
      }

      Swal.fire({
        title: 'Detalle del Reto',
        width: 800,
        html: mensaje,
      });
    } catch (err) {
      console.log(err);
    }
  }
}
