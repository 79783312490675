<form [formGroup]="contactForm" (submit)="updateContact()">

  <div class="row form-perfil">
    <!-- imagen -->
    <div class="col-md-3 text-center" *ngIf="!newContact">
      <div >
        <app-icon-user [userimg]="img"></app-icon-user>
        <div><br />Imagen .JPG o .PNG<br>
          <small>Tamaño Máx: 1024x1024 < 3Mb</small>
        </div>
        <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
        <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file />
        <app-button-user *ngFor="let button of buttons; let i = index" (callbackAction)="onClickButton(button.url)"
          [buttons]="button"></app-button-user>
      </div>
    </div>

    <!-- Nombre Contacto -->
    <div class="col-md-8 text-form">
      <div class="row">
        <div class="col">
          <label for="">{{ this.lbl_nombreContacto }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="nombreSocioFormador">Nombre(s) <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['nombre'])" [label]="'Nombre'"
            [placeholder]="'ej. Beatriz'" maxlength="50"></app-text-input>
        </div>
      </div>

      <!-- Apellidos Contacto -->
      <div class="row">
        <div class="col">
          <label for="apellidos">Apellidos <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['apellidos'])" [label]="'Apellidos'"
            [placeholder]="'ej. Beatriz'" maxlength="50"></app-text-input>
        </div>
      </div>

      <!-- Correo electrónico -->
      <div class="row">
        <div class="col">
          <label for="email">Correo Electrónico <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['email'])" [label]="'Email'"
            [placeholder]="'ej. beatriz.pinzon@ecomoda.com'" maxlength="100"></app-text-input>
        </div>
      </div>

      <!-- Teléfono de Oficina -->
      <div class="row">
        <div class="col">
          <label for="telefonoOficina">Teléfono de Oficina </label>
          <app-text-input [formControl]="$any(contactForm.controls['telefonoOficina'])" [label]="'Teléfono Oficina'"
            [placeholder]="'ej. 5512345678'" maxlength="25"></app-text-input>
        </div>
      </div>

      <!-- Teléfono Celular -->
      <div class="row">
        <div class="col">
          <label for="telefonoMovil">Teléfono Celular </label>
          <app-text-input [formControl]="$any(contactForm.controls['telefonoMovil'])" [label]="'Teléfono Movil'"
            [placeholder]="'ej. 5512345678'" maxlength="16"></app-text-input>
        </div>
      </div>

      <!-- Area a la que pertenece -->
      <div class="row">
        <div class="col">
          <label for="area">Área a la que pertenece <span class="text-danger">*</span></label>
          <div class="text-secondary small" *ngIf="lbl_area_anterior.length > 0">Valor Anterior: {{ lbl_area_anterior }}</div>
          <ng-select [items]="areasData" bindLabel="area" bindValue="idArea"
            formControlName="area" [placeholder]="'Selecciona una opción'">
          </ng-select>
        </div>
      </div>

      <!-- Puesto -->
      <div class="row">
        <div class="col">
          <label for="puesto">Puesto <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['puesto'])" [label]="'Puesto'"
            [placeholder]="'ej. Asistente de Presidencia'" maxlength="150"></app-text-input>
        </div>
      </div>


      <!-- Campus que da servicio -->
      <div class="row d-none">
        <div class="col">
          <label for="domicilio">Campus al que da servicio <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <ng-select [items]="campusData" bindLabel="campus" bindValue="idCampus"
              formControlName="idCampus" [placeholder]="'Selecciona una opción'">
            </ng-select>
            <div
              *ngIf="contactForm.get('idCampus')?.touched && contactForm.get('idCampus')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>

      <!-- Región a la que da servicio -->
      <div class="row d-none">
        <div class="col">
          <label for="domicilio">Región a la que da servicio <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <ng-select [items]="regionData" bindLabel="region" bindValue="idRegion" formControlName="idRegion"
              [placeholder]="'Selecciona una opción'">
            </ng-select>

            <div
              *ngIf="contactForm.get('idRegion')?.touched && contactForm.get('idRegion')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>

          </div>
        </div>
      </div>

      <!-- Exatec -->
      <div class="row mb-3">
        <div class="col">
          <label for="apellidos">¿Eres EXATEC? <span class="text-danger"></span></label>
          <br />

          <span>Si</span>
          &nbsp;
          <input type="radio" class="btn-check" value="true" id="exatec-si" name="exatec"
            [checked]="this.contactForm.value.exatec == true" (change)="onChangeCheckbox($event)" />
          <label class="btn btn-outline-primary" for="exatec-si"></label>

          <span>No</span>
          &nbsp;
          <input type="radio" class="btn-check" value="false" id="exatec-no" name="exatec"
            [checked]="this.contactForm.value.exatec == false" (change)="onChangeCheckbox($event)" />
          <label class="btn btn-outline-primary" for="exatec-no"></label>

        </div>
      </div>

      <!-- botonera -->

      <div class="row" *ngIf="false">
        <div class="col">
          <a class="text-action-show" (click)="listenerShowContacts()">Ver Contactos</a>
        </div>
      </div>

      <div class="row" *ngIf="false">
        <div class="col">
          <a class="text-action-delete" (click)="deleteContact()">Eliminar contacto</a>
        </div>
      </div>

      <div class="row">
        <div class="col-4 d-flex j1ustify-content-center">

          <app-button [buttonType]="'yell'" extraClass="btnyellow">{{ lbl_btnAccion }}</app-button>

          <button class="btn-blue mx-2" type="button" *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil) && newContact == false" (click)="abrirNotificaciones()">Notificaciones</button>

        </div>
      </div>

      <div class="row">
        <div class="col-4 d-flex j1ustify-content-center">
          <button class="error_button" *ngIf="!this.newContact" type="button" (click)="eliminarCuenta()">Eliminar cuenta</button>

          <button class="btn-blue mx-2" type="button" (click)="listenerShowContacts({idSocioFormador:mainContactObj.idSocioFormador ?? 0})">Cancelar</button>
        </div>
      </div>

    </div>
  </div>
</form>
