<app-header-buscador [showSearchBar]="false"></app-header-buscador>
<div>
  <div>
    <app-btn-regresar></app-btn-regresar>
  </div>
  <br><br>
  <div class="iconos-reto">

    <div class="row">
      <div class="col-md-8">
        <div>

          <span class="titleform_sm_sans_2col">
            {{ "pagina_socio_formador_detalles.titulo_razon_social" | translate }}
          </span><br>
          <!-- Nombre Empresa -->
          <span class="titleform_2col">
            <h1>{{ socioFormadorDetalle.nombreSocioFormador }}</h1>
          </span>
          <!-- Razon Social -->
          <span class="titleform_2col">
            <h2>{{ socioFormadorDetalle.razonSocial }}</h2>
          </span>
          <!-- Sitio Web -->
          <span class="titleform_2col d-inline-block">
            <a href="{{ socioFormadorDetalle.paginaWeb }}" target="_blank" rel="noopener noreferrer">
              <h5 class="overridelink">{{ socioFormadorDetalle.paginaWeb }}</h5>
            </a>
          </span>

          <!-- Widget de evaluacion -->
          <div class="row">
            <div class="col-3 mt-1">
              <app-evaluacion [typeEvaluation]="'SOCIO'"
                [ShowCommentsButton]="true"
                [Id]="idSocio"
                [evaluacion]="socioFormadorDetalle.evaluacion"
                [evaluadores]="socioFormadorDetalle.evaluadores"
                [clavePerfil]="userStore.clavePerfil"
                >
              </app-evaluacion>
            </div>
            <div class="col-10"></div>
          </div>

          <!-- retos Exitosos x por SF -->
          <span>
            <app-retos-exitosos [IdSocioFormador]="idSocio"></app-retos-exitosos>
          </span>
        </div>
      </div>
      <!-- Logotipo -->
      <div class="col-md-3 justify-content-end d-flex"><img width="400px" alt="Logo"
          src="{{ socioFormadorDetalle.urlLogo }}" class="imgcolumna_izq"></div>
    </div>

    <div class="row">
      <div class="col-11">

        <div class="row">
          <label class="subtitlecard2">
            {{ "pagina_socio_formador_detalles.informacion_de_empresa" | translate }}
          </label><br /><br />
          <!-- empresa / descripción -->
          <div class="subtitlecard">{{ "pagina_socio_formador_detalles.acerca_socio" | translate }}</div>
          <div class="txtcard">{{ socioFormadorDetalle.empresa }}</div>
        </div>

        <div class="row">
          <!-- Sector -->
          <div class="ico_reto_container col-6" *ngIf="userStore.clavePerfil != 'socio'">
            <div class="ico_reto_container_{{ socioFormadorDetalle.idSector  }}"></div>
          </div>

          <!-- Presencia -->
          <div class="ico_reto_container col-6" *ngIf="userStore.clavePerfil != 'socio'">
            <div class="ico_reto_container_{{ socioFormadorDetalle.presenciaC | lowercase }}"></div>
            <div class="ico_reto_numsem"></div>
            <div class="ico_reto_container_inner_label">{{ socioFormadorDetalle.presenciaC }}</div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-4"></div>

  </div>

  <hr style="color: #BEDCFE">

  <div class="row">

    <div class="col-md-12">

      <div class="row">
        <div class="col">
          <!-- RFC -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.rfc" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.rfc }}
          </div>

          <!-- Corporativo -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">{{
            "pagina_socio_formador_detalles.corporativo" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.grupoComercial }}
          </div>

          <!-- Pais -->
          <div class="subtitlecard">
            {{ "pagina_socio_formador_detalles.pais" | translate }}
          </div>
          <div class="txtcard">
            {{ socioFormadorDetalle.pais }}
          </div>

          <!-- CP -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.cp" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.cp }}
          </div>

          <!-- Estado -->
          <div class="subtitlecard">
            {{ "pagina_socio_formador_detalles.estado" | translate }}
          </div>
          <div class="txtcard">
            {{ socioFormadorDetalle.estado }}
          </div>

          <!-- Ciudad -->
          <div class="subtitlecard">
            {{ "pagina_socio_formador_detalles.ciudad" | translate }}
          </div>
          <div class="txtcard">
            {{ socioFormadorDetalle.ciudad }}
          </div>

          <!-- Colonia -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.colonia" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.colonia }}
          </div>

          <!-- Calle y numero -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.calle_y_numero" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.calle }}
          </div>

          <!-- Sector -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.sector" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.sector }}
          </div>

          <!-- Industria -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.industria" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.industria }}
          </div>

          <!-- Tamaño organizacion -->
          <div class="subtitlecard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ "pagina_socio_formador_detalles.tamanio_organizacion" | translate }}
          </div>
          <div class="txtcard" *ngIf="userStore.clavePerfil != 'socio'">
            {{ socioFormadorDetalle.tamano }}
          </div>

          <!-- Relacion actual con el Tec -->
          <div class="subtitlecard">
            {{ "pagina_socio_formador_detalles.relacion_con_tec" | translate }}</div>
          <div class="txtcard">
            {{ relacionesTec }}
          </div>

        </div>


        <!-- Contactos de la organización -->
        <div class="col-12 mb-5" *ngIf="MostrarContactos()">
          <hr style="color: #BEDCFE">
          <div class="subtitlecard">Personas de contacto de la Organización</div>

          <div class="row mt-3">
            <div class="col-3"><strong>Nombre</strong></div>
            <div class="col-3"><strong>Email</strong></div>
            <div class="col-2"><strong>Área</strong></div>
            <div class="col-2"><strong>Teléfono</strong></div>
            <div class="col-2"><strong>Tipo de contacto</strong></div>
          </div>

          <hr style="color: #BEDCFE">

          <div class="row" *ngFor="let contacto of contactos">
            <div class="col-3">{{ contacto.nombre }} {{ contacto.apellidos }} </div>
            <div class="col-3">{{ contacto.email}}</div>
            <div class="col-2">{{ getAreaNombre(contacto.area) }}</div>
            <div class="col-2">
              Oficina: {{ contacto.telefonoOficina }}<br />
              Móvil: {{ contacto.telefonoMovil }}</div>
            <div class="col-2">{{ contacto.contactoPrincipal ? 'Principal':'' }}</div>
          </div>


        </div>


      </div>


      <!-- Persona CVDP que da servicio -->
      <div class="row mb-5">

        <span class="subtitlecard">Persona del CVDP que le da servicio</span><br><br><br>
        <div class="row header-cvdps">
          <div class="col-3"> <strong>{{ "pagina_socio_formador_detalles.persona_cvdp_responsable" | translate
              }}</strong></div>
          <div class="col-3"> <strong>{{ "pagina_socio_formador_detalles.correo_electronico" | translate }}</strong>
          </div>
          <div class="col-3"> <strong>{{ "pagina_socio_formador_detalles.region" | translate }}</strong></div>
          <div class="col-3"> <strong>{{ "pagina_socio_formador_detalles.campus" | translate }}</strong></div>
        </div>

        <hr style="color: #BEDCFE">

        <div class="row data-cvdps" *ngFor="let cvdp of listaCVDPs">
          <div class="col-3">{{ cvdp.nombres }} {{ cvdp.apellidos}} </div>
          <div class="col-3">{{ cvdp.email }}</div>
          <div class="col-3">{{ cvdp.region }}</div>
          <div class="col-3">{{ cvdp.campus }}</div>
        </div>


      </div>


      <!-- cartas convenio y colaboracion -->
      <div class="row">
        <div class="col-md-12">

          <div *ngIf="['cvdp', 'cvdp_admin','academia'].includes(this.userStore.clavePerfil)">

            <!-- Agregar nuevo convenio -->
            <div iv class="row" *ngIf="['cvdp', 'cvdp_admin'].includes(this.userStore.clavePerfil)">
              <div class="col-12 my-4">
                <button type="button" class="btn-amarillo" (click)="toogleDivCargaConvenio()"> Agregar un convenio <img
                    id="imgQuieroVincularlo" alt="Convenio" width="20px" src="{{ imgQuieroVincularlo }}"></button>
              </div>

              <div *ngIf="mostrarDivConvenio">
                <form (ngSubmit)="onSubmit()" class="frm_registro_sf" [formGroup]="convenioForm">

                  <div class="col-3 mt-1">
                    <label for="fechaInicio">Fecha Inicial:</label>
                  </div>
                  <div class="col-3">
                    <input class="form-control" type="date" id="fechaInicio" formControlName="fechaInicio">
                    <div class="requerido" *ngIf="convenioForm.get('fechaInicio')?.touched && convenioForm.get('fechaInicio')?.errors?.['required']">
                      Campo obligatorio
                    </div>
                  </div>


                  <div class="col-3 mt-1">
                    <label for="fechaFin">Fecha Final:</label>
                    <input class="form-control" type="date" id="fechaFin" formControlName="fechaFin">
                    <div class="requerido" *ngIf="convenioForm.get('fechaFin')?.touched && convenioForm.get('fechaFin')?.errors?.['required']">
                      Campo obligatorio
                    </div>
                  </div>

                  <div class="col-3 mt-1">
                    <label for="personaFirma">Persona que firma:</label>
                    <input class="form-control" type="text" id="personaFirma" formControlName="personaFirma">
                    <div class="requerido" *ngIf="convenioForm.get('personaFirma')?.touched && convenioForm.get('personaFirma')?.errors?.['required']">
                      Campo obligatorio
                    </div>
                  </div>

                  <div class="col-5 mt-2">
                    <label for="fechaInicio">Convenio: &nbsp;</label>
                    <button type="button" class="btn-amarillo" (click)="file.click()">Subir</button>
                    <input class="d-none" type="file" (change)="selImagen($event)" accept="application/pdf" #file />
                    <div>{{ fileNameSelected }}</div>

                    <div class="requerido" *ngIf="convenioForm.get('urlCartaConvenio')?.touched && convenioForm.get('urlCartaConvenio')?.errors?.['required']">
                      Campo obligatorio
                    </div>

                  </div>
                  <br><br>

                  <button type="button" class="btn-amarillo mt-1" (click)="guardarConvenio()">Guardar Convenio</button>
                </form>
              </div>

            </div>

            <!-- Listado de Convenio -->
            <span class="txtcard">
              <label class="subtitlecard">{{ "pagina_socio_formador_detalles.carta_solicitud_convenio" | translate
                }}</label>
              <app-table [backgroundColor]="backgroundTable" [columns]="convenioColumns"
                [data]="listCartaConvenio"></app-table>
            </span><br>
          </div>

          <!-- Listado de Cartas Colaboracion -->
          <div *ngIf="['cvdp', 'cvdp_admin','academia'].includes(this.userStore.clavePerfil)">
            <span class="subtitlecard">{{ "pagina_socio_formador_detalles.colaboracion" | translate
              }}</span>

            <span class="txtcard">

              <app-table [backgroundColor]="backgroundTable" [columns]="colaboracionColumns"
                [data]="listCartaColaboracion"></app-table>

            </span><br>
          </div>


        </div>
      </div>

      <app-academico-solicitud-sf-form
        [contextoOrigen]="contextoOrigen"
        [socioFormadorDetalle]="socioFormadorDetalle"
        [MatchObjSocio] = "MatchObjSocio"
        [idReto]="idReto"
        [idSocio]="idSocio"
        [idLogBusquedaSocio]="idLogBusquedaSocio" 
        [buttonText] ="'¡Quiero Vincularme!'"
      ></app-academico-solicitud-sf-form>

      <div class="d-none">
        <!-- Opción que se muestra cuando viene del Match -->
        <div *ngIf="tieneReto && contextoOrigen == 'match'">

          <!-- btn quiero vincularme -->
          <div class="col-4 mt-4">
            <button (click)="mostarFormularioVinculacion()" class="btn-amarillo">
              {{ "pagina_retos_detalles.quiero_vincularme" |
              translate }}&nbsp;<img id="imgQuieroVincularlo" alt="Quiero vincularlo" width="20px"
                src="{{ imgQuieroVincularlo }}"></button>
          </div>


          <div *ngIf="mostrarFormulario" class="pt-3">

            <div class="row d-none">
              <div class="col-12">
                <div class="subtitlecard">Reto a vincular:</div>
                <div class="txtcard">{{ NombreReto }}</div>
              </div>
            </div>


            <!-- Académico solicitar a nombre de otro -->
            <div class="row">
              <div class="col-12">
                <span class="form-check form-check-inline my-2">
                  <input class="form-check-input-check me-1" id="Solicitar_en_nombre_de_otro"
                    (change)="changeAcademicoEnNombreDeOtro($event)" type="checkbox" />
                  <label class="check-label me-3" for="Solicitar_en_nombre_de_otro"> La solicitud se realizará en nombre
                    de alguien más</label>
                </span>
              </div>
              <div class="col-6" *ngIf="mostrarSeccionOtroProfesor">
                <ng-select (ngModel)="otroAcademicoSeleccionado" (change)="seleccionarOtroAcademico($event)"
                  [items]="academicosData" [bindLabel]="'nombreUsuario'" [bindValue]="'idUsuario'">
                </ng-select>
              </div>
            </div>



            <!-- Periodo-->
            <span class="question-text">{{ 'pagina_retos_detalles.selecciona_periodo_semestral_para_sf' | translate
              }}</span><span class="requerido">&nbsp;*</span><br />
            <div class="requerido" *ngIf="pedirPeriodo">
              Este campo es requerido
            </div>
            <div class="col-3">
              <select class="form-select form-select-sm mb-4 mt-2" id="periodoSemestral" name="periodoSemestral"
                (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
                <option value="">Seleccione una opción</option>
                <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }}">
                  {{ el.periodo }}
                </option>
              </select>
            </div>


            <!-- Periodo semanal -->
            <div class="question-text mt-3">
              {{ 'pagina_retos_detalles.selecciona_periodo_semanal_para_sf' | translate }}
              <span class="requerido">&nbsp;*</span>
            </div>
            <div class="requerido" *ngIf="pedirPeriodoSemanal">
              Este campo es requerido
            </div>
            <div>
              <span class="form-check form-check-inline my-2" *ngFor="let periodoSemana of periodoSemanalData">
                <input class="form-check-input-check me-1" id="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"
                  (change)="verificaCheckPeriodoSemanal($event)"
                  [checked]="verificaChecked(periodoSemana.idPeriodoSemanal)" value="{{ periodoSemana.idPeriodoSemanal}}"
                  type="checkbox" />
                <label class="check-label me-3" for="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"> {{
                  periodoSemana.periodoSemanal}}</label>
              </span>
            </div>
            <!--Modalidad-->
            <span class="question-text mt-3">{{ 'pagina_retos_detalles.selecciona_modalidad' | translate
              }}</span><span class="requerido">&nbsp;*</span><br />
            <div class="requerido" *ngIf="pedirModalidad">
              Este campo es requerido
            </div>
            <div class="col-3">
              <select class="form-select form-select-sm mb-4 mt-2" id="modalidad" name="modalidad"
                (change)="seleccionarModalidad($event)" placeholder="Selecciona modalidad">
                <option value="">Seleccione una opción</option>
                <option *ngFor="let el of this.listModalidad" value="{{ el.idModalidad }}">
                  {{ el.modalidad }}
                </option>
              </select>
            </div>

            <div class="row my-3">
              <div class="col-12">
                <button type="button" class="btn-amarillo" (click)="quieroVincularme()"> Solicitar Vinculación </button>
              </div>
            </div>
          </div>
        </div>

        <!-- ***** Opción que se muestra cuando viene del banco de socios -->
        <div class="row" *ngIf="MostrarSolicitudVinculacionAcademico()">
          <div class="col-12">
            <button type="button" class="btn-amarillo" (click)="toogleDivSolVinculacion()"> Quiero Vincularlo <img
                id="imgQuieroVincularlo" alt="Quiero vincularlo" width="20px" src="{{ imgQuieroVincularlo }}"></button>
          </div>
        </div>
        <div *ngIf="MostrarSolicitudVinculacionAcademico() && mostrarDivSolVinculacion" id="SolicitudVinculacion">
          <hr style="color: #BEDCFE">
          <span class="subtitlecard">Quiero vincular este Socio Formador a un Reto:</span>

          <!-- Académico solicitar a nombre de otro -->
          <div class="row">
            <div class="col-12">
              <span class="form-check form-check-inline my-2">
                <input class="form-check-input-check me-1" id="Solicitar_en_nombre_de_otro"
                  (change)="changeAcademicoEnNombreDeOtro($event)" type="checkbox" />
                <label class="check-label me-3" for="Solicitar_en_nombre_de_otro"> La solicitud se realizará en nombre de
                  alguien más</label>
              </span>
            </div>
            <div class="col-6" *ngIf="mostrarSeccionOtroProfesor">
              <ng-select (ngModel)="otroAcademicoSeleccionado" (change)="seleccionarOtroAcademico($event)"
                [items]="academicosData" [bindLabel]="'nombreUsuario'" [bindValue]="'idUsuario'">
              </ng-select>
            </div>
          </div>

          <!-- Solicitar Periodo y periodo Semanal -->
          <div class="pt-3">

            <div class="row d-none">
              <div class="col-12">
                <div class="subtitlecard">Reto a vincular:</div>
                <div class="txtcard">{{ NombreReto }}</div>
              </div>
            </div>

            <!-- Periodo-->
            <span class="question-text">{{ 'pagina_retos_detalles.selecciona_periodo_semestral_para_sf' | translate
              }}</span><span class="requerido">&nbsp;*</span><br />
            <div class="requerido" *ngIf="pedirPeriodo">
              Este campo es requerido
            </div>
            <div class="col-3">
              <select class="form-select form-select-sm mb-4 mt-2" id="periodoSemestral" name="periodoSemestral"
                (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
                <option value="">Seleccione una opción</option>
                <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }} ">
                  {{ el.periodo }}
                </option>
              </select>
            </div>


            <!-- Periodo semanal -->
            <div class="question-text mt-3">
              {{ 'pagina_retos_detalles.selecciona_periodo_semanal_para_sf' | translate }}
              <span class="requerido">&nbsp;*</span>
            </div>
            <div class="requerido" *ngIf="pedirPeriodoSemanal">
              Este campo es requerido
            </div>
            <div>
              <span class="form-check form-check-inline my-2" *ngFor="let periodoSemana of periodoSemanalData">
                <input class="form-check-input-check me-1" id="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"
                  (change)="verificaCheckPeriodoSemanal($event)"
                  [checked]="verificaChecked(periodoSemana.idPeriodoSemanal)" value="{{ periodoSemana.idPeriodoSemanal}}"
                  type="checkbox" />
                <label class="check-label me-3" for="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"> {{
                  periodoSemana.periodoSemanal}}</label>
              </span>
            </div>

            <!--Modalidad-->
            <span class="question-text mt-3">{{ 'pagina_retos_detalles.selecciona_modalidad' | translate
              }}</span><span class="requerido">&nbsp;*</span><br />
            <div class="requerido" *ngIf="pedirModalidad">
              Este campo es requerido
            </div>
            <div class="col-3">
              <select class="form-select form-select-sm mb-4 mt-2" id="modalidad" name="modalidad"
                (change)="seleccionarModalidad($event)" placeholder="Selecciona modalidad">
                <option value="">Seleccione una opción</option>
                <option *ngFor="let el of this.listModalidad" value="{{ el.idModalidad }}">
                  {{ el.modalidad }}
                </option>
              </select>
            </div>

          </div>

          <form [formGroup]="vinculacionForm" class="mt-4" (submit)="quieroVincularmeaReto()" autocomplete="off">

            <div class="col-6">
              <span class="question-text">Selecciona la Escuela <span class="requerido">*</span></span>

              <div class="requerido" *ngIf="mostrarRequeridos && vinculacionForm.get('idEscuela')?.errors?.['required']">
                Este campo es requerido.
              </div>

              <ng-select aria-label="Default example" formControlName="idEscuela"
                (change)="this.getUnidadFormativa($event)">
                <ng-option *ngFor="let escuela of escuelaData" [value]="escuela.idEscuela">
                  {{ escuela.escuela }}
                </ng-option>
              </ng-select>
              <br>
            </div>

            <div class="col-6">
              <span class="question-text">Selecciona la Unidad Formativa <span class="requerido">*</span></span>


              <div class="requerido"
                *ngIf="mostrarRequeridos && vinculacionForm.get('idUnidadFormativa')?.errors?.['required']">
                Este campo es requerido.
              </div>

              <ng-select aria-label="Default example" formControlName="idUnidadFormativa"
                (change)="getRetoDetail($event)">
                <ng-option *ngFor="let unidad of unidadFormativaData" [value]="unidad.idUnidadFormativa">
                  {{ unidad.clave }}_{{ unidad.unidadFormativa }}
                </ng-option>
              </ng-select>
              <br>
            </div>

            <!-- Información del reto seleccionado cuando viene del Banco -->
            <div *ngIf="tieneReto">
              <div class="row">
                <div class="col-12">
                  <span class="subtitlecard">Reto a vincular:</span><br />
                  <span class="txtcard">{{ NombreReto }}</span>
                </div>
              </div>

              <div class="col bkgatomic" *ngIf="!retoInfo">
                <span class="titatomic">Selecciona un reto para ver su información
                </span>
              </div>
              <div class="col bkgatomic" *ngIf="retoInfo">
                <div class="row mb-4">
                  <div class="col">
                    <img class="imgpchip_med" alt="icon" src="assets/svg/icon_info.svg" />&nbsp;
                    <span class="titatomic">
                      <strong>Información del reto asociado a la UF</strong>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-2">
                    <span class="titsans">{{ retoInfo.reto }}</span>
                  </div>
                  <div class="col-12">
                    <strong>Descripción:</strong>
                  </div>
                  <div class="col-12 mb-2">
                    <span>{{ retoInfo.descripcion }}</span>
                  </div>
                  <div class="col-12">
                    <strong>Objetivo del reto:</strong>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ retoInfo.objetivo }}</span>
                </div>

                <div class="col-12">
                  <strong>Entregable:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ retoInfo.entregable }}</span>
                </div>

                <div class="col-12">
                  <strong>Idioma:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ lbl_idiomas }}</span>
                </div>

                <div class="col-12">
                  <strong>Semestre:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ lbl_semestre }}</span>
                </div>

                <div class="col-12">
                  <strong>Duración:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ retoInfo.semanas }}</span>
                </div>

                <div class="col-12">
                  <strong>Periodo Semanal:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ lbl_periodoSemanal }}</span>
                </div>

                <div class="col-12">
                  <strong>Periodo Semestral:</strong>
                </div>
                <div class="col-12 mb-2">
                  <span>{{ lbl_periodoSemestral }}</span>
                </div>

                <div class="row">
                  <div class="col-3 container-icons mb-3" *ngFor="let infoIcon of retoInfo.generalInfoIcons">
                    <app-icon-border [icon]="infoIcon.icon" [title]="infoIcon.title"></app-icon-border>
                  </div>
                </div>
                <div class="row" *ngIf="false">
                  <div class="col d-flex justify-content-center">
                    <app-button extraClass="">Crear nuevo reto a partir de existente</app-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn-amarillo"> Solicitar Vinculación
                </button>
              </div>
            </div>

          </form>


        </div>

      </div>


    </div>
  </div>

</div>


<!-- Modal de la carta solicitud -->
<ng-template #content>
  <div class="modal-header">

    <button type="button" class="btn-close" aria-label="Close" (click)="closeConvenio()"></button>
  </div>
  <div class="modal-body">
    <app-convenio-viewer (idSocioFormador)="0" [showFilePDF]="filePdfFirmado"></app-convenio-viewer>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-amarillo" (click)="downloadPDF()">Descargar PDF</button>
  </div>


</ng-template>
