import { Column, optionsTextCol } from "@shared/components/table/table.component";


export const COLS_SOLICITUDES_SF_POTENCIAL: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaRegistro',
    },
    {
      title: 'Socio Formador',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'link',
    },
    {
      title: 'Persona de contacto',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'imageTitle',
    },
    {
      title: 'Campus de Atn.',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Estatus',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'estatus',
    },
    {
      title: 'Acciones',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    },
  ];

  export const COLS_SF_SOLICITANDO_RETO: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaSolicitud',
    },
    {
      title: 'Socio Formador',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'link',
    },
    {
      title: 'Persona de contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Campus de Atn',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Reto a Vincular',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'retoVincular',
    },
    {
      title: 'Duración',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'duracion',
    },
    {
      title: 'Periodo',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'periodo',
    },
    {
      title: 'Campus a Vincular',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Acciones',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    },
  ];

  export const COLS_ACADEMICO_BUSCA_SF: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaRegistro',
    },
    {
      title: 'Académico, Correo y Campus',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Socio Formador',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'imageTitle',
    },
    {
      title: 'Reto a vincular y Escuela',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'unidadFormativa',
    },
    {
      title: 'Alcance del SF',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Periodo Semestral',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemestral',
    },
    {
      title: 'Periodo Semanal',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemanal',
    },
    {
      title: 'Acciones',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    },
  ];

  export const COLS_PROBLEMATICAS: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaSolicitud',
    },
    {
      title: 'Socio Formador',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'imageTitle',
    },
    {
      title: 'Contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Problemática',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'link',
    },
    {
      title: 'Campus de Atn del SF',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campusCoordinador',
    },
    {
      title: 'Escuela',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Campus solicitado',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Acciones',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    }
  ];

  export const COLS_PROBLEMATICAS_COMMENTS: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaSolicitud',
    },
    {
      title: 'Socio Formador',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'imageTitle',
    },
    {
      title: 'Contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Problemática',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'link',
    },
    {
      title: 'Campus de Atn del SF',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campusCoordinador',
    },
    {
      title: 'Escuela',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Campus solicitado',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Comentarios',
      sizeCol: '1',
      classType: 'd-flex dashbd_rows_img justify-content-center',
      typeColumn: optionsTextCol.message,
      key: 'message',
    },
  ];

  export const COLS_PROBLEMATICAS_TODOS: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaSolicitud',
    },
    {
      title: 'Socio Formador',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'imageTitle',
    },
    {
      title: 'Contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Problemática',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'link',
    },
    {
      title: 'Campus de Atn del SF',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campusCoordinador',
    },
    {
      title: 'Escuela',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Campus solicitado',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Comentarios',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.message,
      key: 'message',
    },
  ];


  export const COLS_LISTADO_RETOS: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Socio Formador",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },
    {
      title: "Contacto del SF",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "contactPerson",
    },
    {
      title: "Periodo Semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Periodo Semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Profesor Coordinador",
      sizeCol:"2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];


  /**Documentacion de reto */

  export const COLS_LISTADO_RETOS_FORM: Column[] = [
    {
      title: "Fecha Inicio",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "3",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus, Esc y CRN",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "Alcance del reto",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "proyecto",
    },

    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_LISTADO_RETOS_CURS: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "3",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus, Esc y CRN",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "Profesor coordinador y correo",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },

    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_LISTADO_RETOS_CONC: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "3",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus, Esc y CRN",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "Socio Formador",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },
    {
      title: "Estudiantes",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "inscritos",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

