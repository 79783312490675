<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>
<div>
  <section
    class="container-fluid"
    *ngIf="retoDetalle !== null && retoDetalle !== undefined"
  >
    <!-- Información del reto -->
    <section class="row mt-5">
      <div class="col-md-7">
        <div>
          <span class="titleform_sm_sans_2col">{{
            "pagina_retos_detalles.titulo_reto" | translate
          }}</span
          ><br />
          <span class="titleform_2col">{{ retoDetalle.reto }}</span
          ><br />
          <span class="subtitleform_2c"
            >{{ "pagina_retos_detalles.titulo_uf" | translate }}
            {{ retoDetalle.unidadFormativa }}</span
          ><br />

          <app-socios-exitosos [IdReto]="idReto"></app-socios-exitosos>

          <!-- Widget de evaluacion -->
          <div class="row">
            <div class="col-3 mt-1">
              <app-evaluacion
                [typeEvaluation]="'RETO'"
                [Id]="idReto"
                [evaluacion]="retoDetalle.evaluacion"
                [evaluadores]="retoDetalle.evaluadores"
                [ShowCommentsButton]="true"
                [clavePerfil]="clavePerfil"
              >
              </app-evaluacion>
            </div>
            <div class="col-10"></div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div class="text-center">
          <img src="{{ imgReto }}" class="imgcolumna_izq" alt="Imagen Reto" />

          <div *ngIf="mostrarAltaArchivo">
            <div>
              <br />Imagen .JPG o .PNG<br />
              <small>Tamaño Máx: 1024x1024 < 3Mb</small>
            </div>
            <div class="editFoto fileUploadButton" (click)="file.click()">
              <br />Editar Foto
            </div>
            <input
              class="d-none"
              type="file"
              (change)="selImagen($event)"
              accept="image/*"
              #file
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Iconos - Detalle del reto  -->
    <section class="row mt-2">
      <div class="col-md-12">
        <div class="ico_reto_container">
          <div class="ico_reto_container_local"></div>
          <div class="ico_reto_numsem"></div>
          <div class="ico_reto_container_inner_label">{{ lbl_alcance }}</div>
        </div>

        <div class="ico_reto_container">
          <div class="ico_reto_container_semestre"></div>
          <div class="ico_reto_numsem"></div>
          <div class="ico_reto_container_inner_label">{{ lbl_semestre }}</div>
        </div>

        <div class="ico_reto_container">
          <div class="ico_reto_container_calendario"></div>
          <div class="ico_reto_numsem">&nbsp;{{ lbl_semana_numero }}</div>
          <div class="ico_reto_container_inner_label">{{ lbl_semana }}</div>
        </div>

        <div class="ico_reto_container">
          <div class="ico_reto_container_lang"></div>
          <div class="ico_reto_numlang">{{ lbl_idioma_numero }}</div>
          <div class="ico_reto_container_inner_label">{{ lbl_idioma }}</div>
        </div>

        <div class="ico_reto_container" *ngIf="retoDetalle.sentidoHumano">
          <div class="ico_reto_container_SentidoHumano"></div>
          <div class="ico_reto_container_inner_label_SH">
            Reto con Sentido Humano
          </div>
        </div>
      </div>
    </section>

    <!-- Escuela y Programa Académico -->
    <div class="row mt-5">
      <div class="col-md-8">
        <div class="row">
          <div class="col">
            <section>
              <span class="subtitlecard">{{
                "pagina_retos_detalles.escuela" | translate
              }}</span
              ><br />
              <span class="subtitleseccard my-2">
                {{ retoDetalle.escuela }} </span
              ><br />
              <span class="subtitlecard">{{
                "pagina_retos_detalles.programa_academico" | translate
              }}</span
              ><br />
              <span class="subtitleseccard my-2">
                {{ lbl_det_programaAcademico }} </span
              ><br />
            </section>
          </div>
        </div>
      </div>
    </div>

    <!-- Alcance, semestre, idioma, duracion, p. semestral, p.semanal -->
    <div class="row">
      <div class="col-3 my-2">
        <span class="subtitlecard">Alcance: </span><br />
        <span class="subtitleseccard">{{ lbl_det_alcance }}</span>
      </div>
      <div class="col-3 my-2">
        <span class="subtitlecard">Semestre: </span><br />
        <span class="subtitleseccard">{{ lbl_det_semestre }}</span>
      </div>
      <div class="col-3 my-2">
        <span class="subtitlecard">Idioma: </span><br />
        <span class="subtitleseccard">{{ lbl_det_idioma }}</span>
      </div>
      <div class="col-3 my-2">
        <span class="subtitlecard">Duración: </span><br />
        <span class="subtitleseccard">{{ retoDetalle.semanas }}</span>
      </div>
      <div class="col-3 my-2">
        <span class="subtitlecard">Periodo Semestral: </span><br />
        <span class="subtitleseccard">{{ lbl_periodosSemestrales }}</span>
      </div>
      <div class="col-9 my-2">
        <span class="subtitlecard">Periodo Semanal: </span><br />
        <span class="subtitleseccard">{{ lbl_periodosSemanales }}</span>
      </div>
      <div class="col-12 my-2">
        <span class="subtitlecard"
          >Horas del servicio social que acredita: </span
        ><br />
        <span class="subtitleseccard">{{
          retoDetalle.horasServicioSocial
        }}</span>
      </div>
    </div>

    <hr class="mb-5" />

    <!-- Seccion 2 Descripción del reto -->
    <div class="row">
      <!-- Descripcion, objetivo entregable -->
      <section class="col-md-12">
        <span class="subtitlecard">{{
          "pagina_retos_detalles.descripcion" | translate
        }}</span
        ><br />
        <span class="subtitleseccard my-4">{{ retoDetalle.descripcion }}</span
        ><br />

        <span class="subtitlecard">{{
          "pagina_retos_detalles.objetivo" | translate
        }}</span
        ><br />
        <span class="subtitleseccard my-4"> {{ retoDetalle.objetivo }} </span
        ><br />

        <span class="subtitlecard">{{
          "pagina_retos_detalles.entregable" | translate
        }}</span
        ><br />
        <span class="subtitleseccard my-4"> {{ retoDetalle.entregable }} </span
        ><br />
      </section>

      <!-- Sector, tamano, industria, presencia, descripcion del SF -->
      <section class="col-md-12">
        <span
          class="subtitlecard"
          [innerHTML]="'pagina_retos_detalles.label_requisitos' | translate"
        ></span
        ><br />
        <span
          class="subtitleseccard"
          [innerHTML]="
            'pagina_retos_detalles.label_requisitos_subtitulo' | translate
          "
        ></span
        ><br />

        <div class="row spacer_top" *ngIf="retoDetalle.sectores">
          <div class="col-12"></div>
          <section class="col-md-12">
            <div>
              <span class="imgcard"
                ><img
                  src="/assets/img/icon_empresa.png"
                  alt="Sector"
                  class="imgcarrousel_reto"
              /></span>
              <span class="txtcard ml-5" [innerHTML]="lbl_sf_sectores"></span
              ><br />
            </div>
          </section>
        </div>

        <div class="row spacer_top" *ngIf="retoDetalle.tamanos">
          <div class="col-12"></div>
          <section class="col-md-12">
            <div>
              <span class="imgcard"
                ><img
                  src="/assets/img/icon_empresa.png"
                  alt="Tamaño"
                  class="imgcarrousel_reto"
              /></span>
              <span class="txtcard ml-5" [innerHTML]="lbl_sf_tamanos"></span
              ><br />
            </div>
          </section>
        </div>

        <div class="row spacer_top" *ngIf="retoDetalle.industrias">
          <div class="col-12"></div>
          <section class="col-md-12">
            <div>
              <span class="imgcard"
                ><img
                  src="/assets/img/icon_empresa.png"
                  alt="Industria"
                  class="imgcarrousel_reto"
              /></span>
              <span class="txtcard ml-5" [innerHTML]="lbl_sf_industria"></span
              ><br />
            </div>
          </section>
        </div>

        <div class="row spacer_top" *ngIf="retoDetalle.presencias">
          <div class="col-12"></div>
          <section class="col-md-12">
            <div>
              <span class="imgcard"
                ><img
                  src="/assets/img/icon_empresa.png"
                  alt="Presencia del Socio Formador"
                  class="imgcarrousel_reto"
              /></span>
              <span class="txtcard ml-5" [innerHTML]="lbl_sf_presencias"></span
              ><br />
            </div>
          </section>
        </div>

        <div class="row spacer_top" *ngIf="retoDetalle.descripcion">
          <div class="col-12"></div>
          <section class="col-md-12">
            <div>
              <div class="subtitlecard">
                Descripción del Socio Formador sugerido:
              </div>
              <span class="txtcard ml-5" [innerHTML]="lbl_sf_descripcion"></span
              ><br />
            </div>
          </section>
        </div>
      </section>
    </div>

    <!-- Mostrar la información de Vinculación al Socio -->
    <div class="row">
      <section
        class="col-md-12 col-xs-12 mb-6"
        *ngIf="clavePerfil === 'socio' && modoConsulta === false"
      >
        <!-- btn quiero vincularme -->
        <div class="col-4 mt-4">
          <button (click)="mostarFormularioVinculacion()" class="main_button">
            {{
              "pagina_retos_detalles.quiero_vincularme" | translate
            }}&nbsp;<img
              id="imgQuieroVincularlo"
              alt="Quiero vincularlo"
              width="20px"
              src="{{ imgQuieroVincularlo }}"
            />
          </button>
        </div>

        <div *ngIf="mostrarFormulario" class="pt-3">
          <div class="color-text-blue-note">
            NOTA: Los periodos semestrales y la oferta en los campus pueden
            variar de acuerdo con la programación de grupos. Consulta la
            disponibilidad con tu contacto del CVDP.
          </div>

          <!-- Periodo -->
          <span class="question-text mt-4">{{
            "pagina_retos_detalles.selecciona_periodo_semestral" | translate
          }}</span
          ><br />
          <div class="text-danger" *ngIf="pedirPeriodoAcademico">
            Este campo es requerido
          </div>
          <select
            class="form-select form-select-sm"
            id="periodoSemestral"
            name="periodoSemestral"
            (change)="seleccionarPeriodo($event)"
            placeholder="Selecciona un periodo"
          >
            <option value="">Seleccione una opción</option>
            <option
              *ngFor="let el of this.periodoAcademicoData"
              value="{{ el.idPeriodo }} "
            >
              {{ el.periodo }}
            </option>
          </select>

          <!-- Campus donde se lleva el reto -->
          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-12 mt-4">
                <span class="question-text">
                  Campus donde te gustaría llevar a cabo el Reto
                  <span class="text-danger">*</span>
                </span>
              </div>

              <div class="text-danger" *ngIf="pedirListaCampus">
                Este campo es requerido
              </div>

              <div class="col-md-12 espacio">
                <span class="text-muted">Selecciona una o más opciones</span>
                <div class="row">
                  <div class="col-md-12 answer-text">
                    <div class="row">
                      <div class="col-6">
                        <div
                          class="col col-md-12"
                          *ngFor="let campus of seccion1"
                        >
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="{{ campus.idCampus }}-{{ campus.campus }}"
                            autocomplete="off"
                            value="{{ campus.idCampus }}"
                            (change)="onCheckboxChange($event, 'campus')"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="{{ campus.idCampus }}-{{ campus.campus }}"
                          ></label>
                          <span>{{ campus.campus }}</span>
                        </div>
                      </div>

                      <div class="col-6">
                        <div
                          class="col col-md-12"
                          *ngFor="let campus of seccion2"
                        >
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="{{ campus.idCampus }}-{{ campus.campus }}"
                            autocomplete="off"
                            value="{{ campus.idCampus }}"
                            (change)="onCheckboxChange($event, 'campus')"
                          />

                          <label
                            class="btn btn-outline-primary"
                            for="{{ campus.idCampus }}-{{ campus.campus }}"
                          ></label>
                          <span>{{ campus.campus }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Campus coordinador -->
          <div class="col-md-12 mt-4">
            <span class="question-text"
              >Campus donde te gustaría gestionar la vinculación
              <span class="text-danger">*</span></span
            >
          </div>

          <div class="text-danger" *ngIf="pedirCampusCoordinador">
            Este campo es requerido
          </div>

          <select
            class="form-select form-select-sm"
            id="campusReto"
            name="campusReto"
            (change)="seleccionar($event)"
            placeholder="Selecciona un campus"
          >
            <option value="">Seleccione una opción</option>
            <option
              *ngFor="let el of this.selectedCampus"
              value="{{ el.idCampus }} "
            >
              {{ el.campus }}
            </option>
          </select>

          <!-- btn de vinculación -->
          <div class="col-4 mt-4">
            <button (click)="quieroVincularme()" class="main_button">
              {{ "pagina_retos_detalles.enviar" | translate }}
            </button>
          </div>
        </div>
      </section>
    </div>

    <!-- Vinculación de un académico que busca SF, sin SF -->
    <div class="row">
      <!-- Mostrar la información de Vinculación al Académico -->
      <section
        class="col-md-12 col-xs-12 mb-6"
        *ngIf="
          clavePerfil === 'academia' &&
          idEscuelaRetoMaestro != idEscuelaAcademico
        "
      >
        <div class="color-text-red-note pt-3">
          No puedes vincular este reto porque no pertenece a tu escuela.
        </div>
      </section>

      <section
        class="col-md-12 col-xs-12 mb-6"
        *ngIf="
          clavePerfil === 'academia' &&
          idEscuelaRetoMaestro == idEscuelaAcademico
        "
      >
        <app-academico-solicitud-sf-form
          [showDescriptionReto]="false"
          [contextoOrigen]="contextoOrigen"
          [idReto]="idReto"
          [idLogBusquedaSocio]="0"
          [idTipoSolicitud]="ACADEMICOBUSCANDOSFSINSOCIO"
          [buttonText]="'¡Quiero vincularme!'"
        ></app-academico-solicitud-sf-form>

        <!-- Formulario de vinculación -->
        <div class="d-none">
          <!-- btn quiero vincularme -->
          <div class="col-4 mt-4">
            <button (click)="mostarFormularioVinculacion()" class="main_button">
              {{
                "pagina_retos_detalles.quiero_vincularme" | translate
              }}&nbsp;<img
                id="imgQuieroVincularlo"
                alt="Quiero vincularlo"
                width="20px"
                src="{{ imgQuieroVincularlo }}"
              />
            </button>
          </div>

          <div *ngIf="mostrarFormulario" class="pt-3">
            <div class="color-text-red-note">
              NOTA: Esta solicitud de vinculación llegará al CVDP quien te
              apoyará a encontrar al Socio Formador ideal para tu reto, tomando
              como referencia la descripción de la Unidad Formativa.
            </div>

            <!-- Periodo -->
            <span class="question-text mt-4">{{
              "pagina_retos_detalles.selecciona_periodo_semestral" | translate
            }}</span
            ><br />
            <div class="text-danger" *ngIf="pedirPeriodoAcademico">
              Este campo es requerido
            </div>
            <select
              class="form-select form-select-sm"
              id="periodoSemestral"
              name="periodoSemestral"
              (change)="seleccionarPeriodo($event)"
              placeholder="Selecciona un periodo"
            >
              <option value="">Seleccione una opción</option>
              <option
                *ngFor="let el of this.periodoAcademicoData"
                value="{{ el.idPeriodo }} "
              >
                {{ el.periodo }}
              </option>
            </select>

            <!-- Campus donde se lleva el reto -->
            <div class="col-md-12 mt-3 d-none">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <span class="question-text">
                    Campus donde te gustaría llevar a cabo el Reto
                    <span class="text-danger">*</span>
                  </span>
                </div>

                <div class="text-danger" *ngIf="pedirListaCampus">
                  Este campo es requerido
                </div>

                <div class="col-md-12 espacio">
                  <span class="text-muted">Selecciona una o más opciones</span>
                  <div class="row">
                    <div class="col-md-12 answer-text">
                      <div class="row">
                        <div class="col-6">
                          <div
                            class="col col-md-12"
                            *ngFor="let campus of seccion1"
                          >
                            <input
                              type="checkbox"
                              class="btn-check"
                              id="{{ campus.idCampus }}-{{ campus.campus }}"
                              autocomplete="off"
                              value="{{ campus.idCampus }}"
                              (change)="onCheckboxChange($event, 'campus')"
                            />
                            <label
                              class="btn btn-outline-primary"
                              for="{{ campus.idCampus }}-{{ campus.campus }}"
                            ></label>
                            <span>{{ campus.campus }}</span>
                          </div>
                        </div>

                        <div class="col-6">
                          <div
                            class="col col-md-12"
                            *ngFor="let campus of seccion2"
                          >
                            <input
                              type="checkbox"
                              class="btn-check"
                              id="{{ campus.idCampus }}-{{ campus.campus }}"
                              autocomplete="off"
                              value="{{ campus.idCampus }}"
                              (change)="onCheckboxChange($event, 'campus')"
                            />

                            <label
                              class="btn btn-outline-primary"
                              for="{{ campus.idCampus }}-{{ campus.campus }}"
                            ></label>
                            <span>{{ campus.campus }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Campus coordinador -->
            <div class="col-md-12 mt-4 d-none">
              <span class="question-text"
                >Campus donde te gustaría gestionar la vinculación
                <span class="text-danger">*</span></span
              >
            </div>

            <div class="text-danger d-none" *ngIf="pedirCampusCoordinador">
              Este campo es requerido
            </div>

            <select
              class="form-select form-select-sm d-none"
              id="campusReto"
              name="campusReto"
              (change)="seleccionar($event)"
              placeholder="Selecciona un campus"
            >
              <option value="">Seleccione una opción</option>
              <option
                *ngFor="let el of this.selectedCampus"
                value="{{ el.idCampus }} "
              >
                {{ el.campus }}
              </option>
            </select>

            <!-- btn de vinculación -->
            <div class="col-4 mt-4">
              <button (click)="academicoQuieroVincularme()" class="main_button">
                {{ "pagina_retos_detalles.enviar" | translate }}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>
