import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import Utils from "@shared/helpers/utils";
import { IUser } from "@shared/Interface/user";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs";

import {
  COLS_BUSQUEDA_SOCIOS_FORMADORES,
  COLS_LISTADO_RETOS_CONC_ACAD,
  COLS_LISTADO_RETOS_CURS_ACAD,
  COLS_LISTADO_RETOS_FORM_ACAD,
  COLS_RETOS,
  COLS_SOCIOS_FORMADORES_VINCULAR,
} from "./academia-dashboard.columns";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { environment } from "environments/environment";

@Component({
  selector: "app-academia-dashboard",
  templateUrl: "./academia-dashboard.component.html",
  styleUrls: ["./academia-dashboard.component.css"],
})
export class AcademiaDashboardComponent implements OnInit {

  backgroundTable = "#F6F6F6";
  titleSociosFormadores = {
    title: "Socios Formadores que se quieren vincular a mis retos",
    link: "/academia/solicitud-vinculacion-retos-existente",
  };
  retosSociosFormadores = {
    title: "Mis búsquedas de Socios Formadores",
    link: "/academia/retos-busquedo-socio",
    /*button: 'Crear nueva vinculación',
    callbackClick: this.vinculacionReto,*/
  };
  titleRetos = {
    title: "Mis Retos",
    link: "/retos-activos",
  };
  // ** Mis busquedas de Socios Formadores
  retosSocioColumns = COLS_BUSQUEDA_SOCIOS_FORMADORES;
  retosSocioData: any;

  // ** Mis retos  (Activos / Mi historial de retos)
  retosColumns = COLS_RETOS;
  retosData: any;
  retosAllData: any;

  // ** Socios Formadores que se quieren vincular a retos
  sociosFormadoresColumns = COLS_SOCIOS_FORMADORES_VINCULAR;

  sociosFormadoresData: any;
  sociosFormadoresRechazadoaData: any;
  sociosFormadoresAceptadasData: any;

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM_ACAD;
  listadoRetosFormalizacionData: any;

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS_ACAD;
  listadoRetosCursoData: any;

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS_ACAD;
  listadoRetosEvaluacionData: any;

  listdaoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC_ACAD;
  listadoRetosConcluidoData: any;

  status: string = "formalizacion";
  constructor(
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private router: Router
  ) {}

  getBusquedaSocioFormador(resultado: any) {
    return resultado
      .map((solicitudes: any) => ({
        fechaSolicitud: solicitudes.fechaRegistro,
        periodo: solicitudes.periodoSemestral ?? "-",
        periodoSemanal: solicitudes.periodoSemanal ?? "-",
        reto: {
          title: solicitudes.reto,
          subtitle: `${solicitudes.escuela} `,
          link: `/solicitud/academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
        },
        link: {
          title:
            solicitudes.idSocioFormador == 0
              ? "No encontró un Socio Formador"
              : solicitudes.nombreSocioFormador,
          subtitle: solicitudes.rfc,
        },
        contactPerson: {
          title: solicitudes.nombreContacto,
          image: solicitudes.urlFotoContacto,
        },
        alcance: solicitudes.alcance ?? '-' ,
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/solicitud/academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
        },
        estatus: {
          textCol: "En revision",
          statusType: "ev_revision",
        },
      }))
      .slice(0, 3);
  }

  getRetosDashboard(resultado: any) {
    return resultado
      .map((reto: any) => ({
        fechaRegistro: reto.fechaRegistro,
        duracionSemanas: reto.duracionSemanas,
        periodoSemanal: reto.periodoSemanal ?? "-",
        periodoSemestral: reto.periodoSemestral ?? "-",
        campus: reto.campusCoordinador ?? "-",
        reto: {
          title: reto.reto,
          subtitle: reto.unidadFormativa,
          link: `/programacion-reto?id=${reto.idReto}`,
        },
        porcentajeAvance: {
          current: reto.avanceSemanas,
          percentage: reto.porcentajeAvance,
          total: Utils.extractValueWeeks(reto.duracionSemanas),
        },
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/programacion-reto?id=${reto.idReto}`,
        },
        nombreSocioFormador: {
          title: reto.nombreSocioFormador,
          subtitle: reto.rfc,
        },
        contactPerson: {
          title: reto.contacto ?? "-",
          subtitle: reto.correoContacto ?? "-",
        },
      }))
      .slice(0, 3);
  }

  getSociosDashboard(resultado: any) {
    return resultado
      .map((socioaVincular: any) => ({
        campus: socioaVincular.campus,
        duracionSemanas: socioaVincular.duracion ?? "-",
        periodoSemanal: socioaVincular.periodoSemanal ?? "-",
        periodoSemestral: socioaVincular.periodoSemestral ?? "-",
        fechaSolicitud: socioaVincular.fechaRegistro,
        imageTitle: {
          title: socioaVincular.nombreSocioFormador,
          link: `solicitud-vinculacion-a-reto/${socioaVincular.idSocioFormador}/${socioaVincular.idRetoMaestro}/${socioaVincular.idSolicitud}`,
          subtitle: socioaVincular.nombreContacto,
          image: socioaVincular.urlFotoContacto,
        },
        reto: {
          title: socioaVincular.reto,
          subtitle: socioaVincular.escuela,
        },
        nombreSocioFormador: {
          title: socioaVincular.nombreSocioFormador,
          subtitle: socioaVincular.rfc,
        },
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `solicitud-vinculacion-a-reto/${socioaVincular.idSocioFormador}/${socioaVincular.idRetoMaestro}/${socioaVincular.idSolicitud}`,
        },
      }))
      .slice(0, 3);
  }

  async cargarDatosDashboard() {
    let usr: IUser = this.usuarioService.getCurrentUserData();
    let campus = 0;
    let escuela = 0;
    let periodo = 0;
    let pagina = 0;
    let hits = environment.RegistrosPagina;


    // Mis búsquedas de Socios Formadores
    let responseRegistrado = await lastValueFrom(
      this.dashboardService.getAcademiaSolicitudesSF(
        usr.idUsuario,
        "registrado"
      )
    );

    let responseSF = await lastValueFrom(
      this.dashboardService.getAcademiaSolicitudesSF(
        usr.idUsuario,
        "revision"
      )
    );

    let responsePendiente = await lastValueFrom(
      this.dashboardService.getAcademiaSolicitudesSF(
        usr.idUsuario,
        "propuesta"
      )
    );

    let lista = [...responseRegistrado, ...responsePendiente, ...responseSF];
    lista = lista.sort((a, b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
    this.retosSocioData = this.getBusquedaSocioFormador(lista);

    // Mis retos
    // response = await lastValueFrom(
    //   this.dashboardService.getRetosAcademicos(usr.idUsuario, "activo")
    // );
    // this.retosData = this.getRetosDashboard(response);

    // response = await lastValueFrom(
    //   this.dashboardService.getRetosAcademicos(usr.idUsuario, "todos")
    // );
    // this.retosAllData = this.getRetosDashboard(response);

// Documentacion

  let responseRetos;

  try {
    responseRetos = await lastValueFrom(
      this.dashboardService.getRetosAcademicos(
        usr.idUsuario,
        "formalizacion",
        campus,
        escuela,
        periodo,
        pagina,
        hits
      )
    );
    this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
      responseRetos
    ).slice(0,3);
  } catch (error) {
    Utils.processErrorResponse(error);
  }

  try {
    responseRetos = await lastValueFrom(
      this.dashboardService.getRetosAcademicos(usr.idUsuario, "en-curso",campus,
      escuela,
      periodo,
      pagina,
      hits)
    );
    this.listadoRetosCursoData = this.getListadoRetosCursoyEval(
      responseRetos, "en-curso"
    ).slice(0,3);
  } catch (error) {
    Utils.processErrorResponse(error);
  }

  try {
    responseRetos = await lastValueFrom(
      this.dashboardService.getRetosAcademicos(
        usr.idUsuario,
        "evaluacion",
        campus,
        escuela,
        periodo,
        pagina,
        hits
      )
    );
    this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
      responseRetos, "evaluacion"
    ).slice(0,3);
  } catch (error) {
    Utils.processErrorResponse(error);
  }

  try {
    responseRetos = await lastValueFrom(
      this.dashboardService.getRetosAcademicos(usr.idUsuario, "concluido",
      campus,
        escuela,
        periodo,
        pagina,
        hits)
    );

    this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
      responseRetos
    ).slice(0,3);
  } catch (error) {
    Utils.processErrorResponse(error);
  }


    // Socios Formadores que se quieren vincular a retos
    let response = await lastValueFrom(
      this.dashboardService.getAcademiaVinculaciones(
        usr.idUsuario,
        "aprobado"
      )
    );
    this.sociosFormadoresData = this.getSociosDashboard(response);


    response = await lastValueFrom(
      this.dashboardService.getAcademiaVinculaciones(
        usr.idUsuario,
        "aceptado"
      )
    );
    this.sociosFormadoresAceptadasData = this.getSociosDashboard(response);

    response = await lastValueFrom(
      this.dashboardService.getAcademiaVinculaciones(
        usr.idUsuario,
        "rechazado"
      )
    );
    this.sociosFormadoresRechazadoaData = this.getSociosDashboard(response);
  }

  ngOnInit(): void {
    this.cargarDatosDashboard().catch((err) => console.log(err));
  }

  vinculacionReto() {
    this.router.navigate(["/vincula-socios-formadores"]);
  }


  getListadoRetosFormalizacion(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/acad/formalizacion?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      duracion: solicitud.duracionSemanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campusCoordinador ?? "",
        subtitle: solicitud.escuela,
      },
      proyecto: solicitud.crn,
      claveEstatusSolicitud: {
        textCol: "Asignar Socio Formador",
        statusType: "amarillo",
        link: `/acad/formalizacion?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosCursoyEval(resultado, estatus) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/acad/${estatus}?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.duracionSemanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.nombreContacto,
        subtitle: solicitud.correoContacto,
      },
      proyecto: solicitud.crn,
      claveEstatusSolicitud: {
        textCol: solicitud.claveEstatus == "en-curso" ? "Ver" : "Registrar caso de éxito",
        statusType: solicitud.claveEstatus == "en-curso" ?  "primary" : "amarillo",
        link: `/acad/${estatus}?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosConcluido(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/acad/concluido?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.duracionSemanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      inscritos:solicitud.inscritos,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/acad/concluido?reto=${solicitud.idReto}`,
      },
    }));
  }

  getMisProblematicas(resultado) {
    return resultado
      .map((problematica: any) => ({
        fechaRegistro: problematica.fechaRegistro,
        proyecto: {
          title: problematica.proyecto,
          link: `/problematica-registrada?socio=${problematica.idSocio}&problematica=${problematica.idProblematica}`,
        },
        contactPerson: {
          title: problematica.contacto ?? "-",
          subtitle: problematica.correoContacto ?? "-",
        },
        alcance: problematica.escuela ?? "-",
        programaAcademico: problematica.programaAcademico,
        semestre: problematica.semestre ?? "-",
        campus: problematica.campus ?? "-",
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/problematica-registrada?socio=${problematica.idSocio}&problematica=${problematica.idProblematica}`,
        },
      }))
      .slice(0, 3);
  }

  changeViewStatus(status) {
    this.status = status;
  }

}
