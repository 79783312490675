import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { COLS_ACADEMICO_BUSCA_SF } from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
import { lastValueFrom, tap } from "rxjs";

@Component({
  selector: "app-academico-busca-sf",
  templateUrl: "./academico-busca-sf.component.html",
  styleUrls: ["./academico-busca-sf.component.css"],
})
export class AcademicoBuscaSFComponent implements OnInit {
  F2_5_HU69 : boolean;
  F2_4_HU69 : boolean;

  sidebarExpanded = false;
  lang: string = "es";


  solicitudesAcademicosRegistradosData: any = [];
  solicitudesAcademicosData: any = [];
  solicitudesAprobadaAcademicosData: any = [];
  solicitudesRechazadasAcademicosData: any = [];

  solicitudesAcademicosRegistradosDataPage: any = [];
  solicitudesAcademicosDataPage: any = [];
  solicitudesAprobadaAcademicosDataPage: any = [];
  solicitudesRechazadasAcademicosDataPage: any = [];


  backgroundTable = "#F6F6F6";
  academicoColumns = COLS_ACADEMICO_BUSCA_SF;

  ngOnInit(): void {
    this.F2_5_HU69 = this.featFlagService.isFeatureEnabled("F2_5_HU69");
    this.F2_4_HU69 = this.featFlagService.isFeatureEnabled("F2_4_HU69");
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();

    if(this.F2_5_HU69){
      this.loadData();
    }
    if(this.F2_4_HU69){
      this.loadData2_4();
    }
  }


  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  constructor(
    private catalogosService: CatalogosService,
    private usuarioService: UsuariosService,
    private dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
    private featFlagService: FeatureFlagService
  ) {
  }

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  pagina: number = 0;
  hits: number = 0;

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    this.filter[type] = id;
    this.loadData();
  }

  cleanFilters() {
    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
    };
    this.loadData();
  }


  async loadData2_4() {
    let userStore = this.usuarioService.getCurrentUserData();
    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;


    this.solicitudesAcademicosRegistradosData = [];
    this.solicitudesAcademicosRegistradosDataPage = [];
    this.solicitudesAcademicosData = [];
    this.solicitudesAprobadaAcademicosData = [];
    this.solicitudesRechazadasAcademicosData = [];
    this.solicitudesAcademicosDataPage = [];
    this.solicitudesAprobadaAcademicosDataPage = [];
    this.solicitudesRechazadasAcademicosDataPage = [];

    let responseRegistrado = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "registrado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );

    //this.solicitudesAcademicosRegistradosData = this.getAcademicoBuscaSF(responseRegistrado);
    // this.showPaginationDataRegistrados(
    //   this.solicitudesAcademicosRegistradosData.slice(0, 10)
    // );

    let responseRevision = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    let responsePropuesta = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "propuesta",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    let listado = [...responseRegistrado, ...responsePropuesta, ...responseRevision];
    listado = listado.sort((a, b) => b.fechaRegistro.localeCompare(a.fechaRegistro));

    this.solicitudesAcademicosData = this.getAcademicoBuscaSF(listado);
    this.showPaginationDataRevision(
      this.solicitudesAcademicosData.slice(0, 10)
    );

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "aprobado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadaAcademicosData = this.getAcademicoBuscaSF(response);
    this.showPaginationDataAprobado(
      this.solicitudesAprobadaAcademicosData.slice(0, 10)
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "rechazado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasAcademicosData = this.getAcademicoBuscaSF(
      response
    );
    this.showPaginationDataRechazado(
      this.solicitudesRechazadasAcademicosData.slice(0, 10)
    );
  }

  async loadData() {
    let userStore = this.usuarioService.getCurrentUserData();
    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;


    this.solicitudesAcademicosRegistradosData = [];
    this.solicitudesAcademicosRegistradosDataPage = [];
    this.solicitudesAcademicosData = [];
    this.solicitudesAprobadaAcademicosData = [];
    this.solicitudesRechazadasAcademicosData = [];
    this.solicitudesAcademicosDataPage = [];
    this.solicitudesAprobadaAcademicosDataPage = [];
    this.solicitudesRechazadasAcademicosDataPage = [];

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "registrado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );

    this.solicitudesAcademicosRegistradosData = this.getAcademicoBuscaSF(response);
    this.showPaginationDataRegistrados(
      this.solicitudesAcademicosRegistradosData.slice(0, 10)
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    let responsePropuesta = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "propuesta",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    let listado = [...responsePropuesta, ...response];
    listado = listado.sort((a, b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
    this.solicitudesAcademicosData = this.getAcademicoBuscaSF(listado);
    this.showPaginationDataRevision(
      this.solicitudesAcademicosData.slice(0, 10)
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "aprobado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadaAcademicosData = this.getAcademicoBuscaSF(response);
    this.showPaginationDataAprobado(
      this.solicitudesAprobadaAcademicosData.slice(0, 10)
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        idUsuario,
        "rechazado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasAcademicosData = this.getAcademicoBuscaSF(
      response
    );
    this.showPaginationDataRechazado(
      this.solicitudesRechazadasAcademicosData.slice(0, 10)
    );
  }

  getAcademicoBuscaSF(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaRegistro: solicitudes.fechaRegistro,
      periodoSemestral: solicitudes.periodoSemestral ?? "-",
      periodoSemanal: solicitudes.periodoSemanal ?? "-",
      contactPerson: {
        title: solicitudes.nombreAcademico,
        subtitle: solicitudes.correoUsuario,
        description: solicitudes.campus,
        //subtitle: `${solicitudes.campus} - ${this.getSiglas(solicitudes.escuela)}`,
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
        image: solicitudes.urlFotoUsuario,
      },
      imageTitle: {
        title: solicitudes.idSocioFormador == 0 ? 'No se encontró un Socio Formador' : solicitudes.detalleSolicitud,
        subtitle: solicitudes.rfc ?? "-",
      },
      unidadFormativa: {
        title: solicitudes.reto,
        subtitle: solicitudes.escuela,
      },
      alcance: solicitudes.alcance ?? "-",
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  showPaginationDataRegistrados(objData: any) {
    if (objData.length) {
      this.solicitudesAcademicosRegistradosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRevision(objData: any) {
    if (objData.length) {
      this.solicitudesAcademicosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataAprobado(objData: any) {
    if (objData.length) {
      this.solicitudesAprobadaAcademicosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRechazado(objData: any) {
    if (objData.length) {
      this.solicitudesRechazadasAcademicosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }
}
